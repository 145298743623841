import {sanitizer} from "../helpers/sanitizer";
import Check24 from "../unifiedlogin_mobile";

export default function() {
    if (
        'active' === Check24.ul.userState ||
        null === Check24.ul.userLoginType ||
        (Check24.ul.userLoginEmail === null && Check24.ul.userLoginPhone === null) ||
        (Check24.ul.userLoginPhone !== null && Check24.ul.userLoginType === "check24_phone" && !Check24.validate.phone(Check24.ul.userLoginPhone)) ||
        (Check24.ul.userLoginEmail !== null && Check24.ul.userLoginType === "check24" && !Check24.validate.email(Check24.ul.userLoginEmail))
    ) {
        return;
    }

    var prefilledLogin = Check24.ul.userLoginType === "check24_phone" && Check24.ul.userLoginPhone !== null ?
        Check24.ul.userLoginPhone :
        (Check24.ul.userLoginEmail !== null ? Check24.ul.userLoginEmail : "");
    Check24.change("value", "#cl_login", prefilledLogin);

    if (Check24.ul.userLoginPhone !== null && Check24.ul.userLoginType === "check24_phone") {
        Check24.ul.convertMobileStart = true;
        Check24.ul.prefilled = true;
        Check24.ul.ajax.convertMobile(Check24.ul.userLoginPhone, "", Check24.ul.callback.convertMobile);
    } else {
        if (Check24.ul.userLoginEmail !== null && Check24.validate.email(Check24.ul.userLoginEmail)) {
            Check24.ul.prefilled = true;
            Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
            Check24.change("value", "#cl_login_ph", Check24.ul.userLoginEmail);
            Check24.change("html", ".c24-uli-user-data", sanitizer.html(Check24.ul.userLoginEmail));
            Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(Check24.ul.userLoginEmail));
            Check24.ul.ajax.exists(Check24.ul.userLoginEmail, "email", Check24.ul.userData, Check24.ul.callback.exists);
        }
    }
}
