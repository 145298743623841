import {configService} from "../services/configService";
import {sanitizer} from "../helpers/sanitizer";
import {poJD} from "../po/po.js_desktop";
import {browserBackEnum} from "../enum/browserBackEnum";
import Check24 from "../unifiedlogin_desktop";

export default function() {
    // ul-init event
    let initEvent = new Event('uli-init');
    window.dispatchEvent(initEvent);

    // ul-document events
    Check24.document.addEventListener("click", function(e) {
        var hadClass = false;
        if (e.target.closest(".c24-uli-form-ele")) {
            hadClass = e.target.closest(".c24-uli-form-ele").classList.contains("selected");
        }
        if (!e.target.closest(".c24-uli-info-icon") && !e.target.classList.contains("c24-uli-info-icon")) {
            Check24.removeClass(".c24-uli-form-ele", "selected");
            Check24.removeClass(".c24-uli-info-icon", "selected");
            Check24.document.querySelector(".c24-uli-wrapper").style.minHeight = "inherit";
            if (
                (e.target.closest(".c24-uli-form-ele") && (configService.getContext() !== "loginlayer" && configService.getContext() !== "as")) ||
                (e.target.closest(".c24-uli-info-icon") && (configService.getContext() === "loginlayer" || configService.getContext() === "as") && !hadClass)
            ) {
                e.target.closest(".c24-uli-form-ele").classList.add("selected");
                if (e.target.closest(".c24-uli-form-ele").querySelector(".c24-uli-info-text")) {
                    var infoDim = e.target.closest(".c24-uli-form-ele").querySelector(".c24-uli-info-text").getBoundingClientRect();
                    var ulDim = Check24.document.querySelector(".c24-uli-wrapper").getBoundingClientRect();
                    var infoPos = infoDim.height + infoDim.top;
                    var ulPos = ulDim.height + ulDim.top;
                    var ulH = (infoDim.height + (infoDim.top - ulDim.top) + 20) + "px";
                    if (infoPos > ulPos) {
                        Check24.document.querySelector(".c24-uli-wrapper").style.minHeight = ulH;
                    }
                }
            }
            Check24.ul.resetHeight();
        }
    });

    // ul-elements events
    Check24.addEvent(".c24-uli-info-icon", "click", function(e) {
        if (!e.target.closest(".c24-uli-form-ele").classList.contains("selected")) {
            if (
                (e.target.closest(".c24-uli-form-ele") && (configService.getContext() !== "loginlayer" && configService.getContext() !== "as")) ||
                (e.target.closest(".c24-uli-info-icon") && (configService.getContext() === "loginlayer" || configService.getContext() === "as"))
            ) {
                e.target.closest(".c24-uli-form-ele").classList.add("selected");
                if (e.target.closest(".c24-uli-form-ele").querySelector(".c24-uli-info-text")) {
                    var infoDim = e.target.closest(".c24-uli-form-ele").querySelector(".c24-uli-info-text").getBoundingClientRect();
                    var ulDim = Check24.document.querySelector(".c24-uli-wrapper").getBoundingClientRect();
                    var infoPos = infoDim.height + infoDim.top;
                    var ulPos = ulDim.height + ulDim.top;
                    var ulH = (infoDim.height + (infoDim.top - ulDim.top) + 20) + "px";
                    if (infoPos > ulPos) {
                        Check24.document.querySelector(".c24-uli-wrapper").style.minHeight = ulH;
                    }
                }
            }
        } else {
            Check24.removeClass(".c24-uli-form-ele", "selected");
            Check24.removeClass(".c24-uli-info-icon", "selected");
            Check24.document.querySelector(".c24-uli-wrapper").style.minHeight = "inherit";
        }
        Check24.ul.resetHeight();
    });


    Check24.addEvent(".c24-uli-user-change", "click", function() {
        if (Check24.ul.state === "tan" && Check24.ul.registercheckTriggered && Check24.ul.loginState !== "") {
            Check24.ul.init("setlogindata", "");
            return;
        }
        Check24.ul.changeUser();
    });

    Check24.addEvent(".c24-uli-user-known", "click", function() {
        if (Check24.ul.tanContext !== "editphone" && !Check24.hasClass(".c24-uli-wrapper", "c24-uli-force-login")) {
            Check24.ul.changeUser();
        }
    });

    Check24.addEvent(".c24-uli-cl-l-phone-trigger", "click", function() {
        Check24.ul.loginInit = true;
        Check24.change("value", "#cl_login", "");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-cl-l-email-trigger", "click", function() {
        Check24.ul.loginInit = true;
        Check24.change("value", "#cl_login", "");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-cl-r-email-trigger", "click", function() {
        configService.setMode("");
        Check24.ul.loginInit = true;
        Check24.change("value", "#cl_login", "");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-cl-r-sameemail-trigger", "click", function() {
        configService.setMode("");
        var login = Check24.getValue("#cl_login_ph");
        if (Check24.ul.registercheckTriggered) {
            Check24.ul.registercheckTriggered = false;
            Check24.ul.loginType = 'email';
            Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
        }
        var type = Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email";
        Check24.change("html", ".c24-uli-login-explicit-wrapper", sanitizer.html(login));
        Check24.change("html", ".c24-uli-renew .c24-uli-login-wrapper", poJD.trigger.same_email);
        Check24.show(".c24-uli-renew-text-email");
        if (Check24.ul.userData.exists == null) {
            Check24.ul.ajax.exists(login, type, Check24.ul.userData, Check24.ul.callback.existsSimple);
        } else {
            if (Check24.ul.userData.exists === false) {
                Check24.ul.init("register", "email");
            } else {
                Check24.ul.init("renew", "start");
            }
        }
        Check24.change("value", "#cl_login", login);
    });

    Check24.addEvent(".c24-uli-cl-r-samephone-trigger", "click", function() {
        configService.setMode("");
        var login = Check24.getValue("#cl_login_ph");
        var type = Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email";
        Check24.change("html", ".c24-uli-login-explicit-wrapper", sanitizer.html(login));
        Check24.change("html", ".c24-uli-renew .c24-uli-login-wrapper", poJD.trigger.same_mobile);
        Check24.hide(".c24-uli-renew-text-email");
        if (Check24.ul.userData.exists === null) {
            Check24.ul.ajax.exists(login, type, Check24.ul.userData, Check24.ul.callback.existsSimple);
        } else {
            Check24.ul.init("renew", "start");
        }
        Check24.change("value", "#cl_login", login);
    });

    Check24.addEvent(".c24-uli-cl-r-phone-trigger", "click", function() {
        configService.setMode("");
        Check24.ul.loginInit = true;
        Check24.change("value", "#cl_login", "");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-cl-pwreset-trigger", "click", function() {
        Check24.ul.pwResetData = {
            id: null,
            password: null,
            mcode: null,
            ecode: null,
            zipcode: null,
            birthday: null,
            loginInstead: "no",
            questions: null,
            method: null,
            recipient: null,
            sq: null
        };
        if (Check24.ul.registercheckTriggered) {
            Check24.ul.loginType = 'email';
            Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
        }

        var login = Check24.getValue("#cl_login_ph");
        Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper-explicit", sanitizer.html(login));
        if (Check24.ul.loginType === "phone") {
            Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.mobile);
            Check24.ul.pwResetData.id = login;
        } else {
            Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.email);
            Check24.ul.pwResetData.id = login;
        }
        Check24.ul.init("pwreset", "check");
    });

    Check24.addEvent(".c24-uli-back-pwreset", "click", function(e) {
        var methods = Check24.ul.pwResetData.methods;
        if (methods.length > 1) {
            Check24.ul.init("pwreset", "choose");
        } else {
            if (e.target.classList && e.target.classList.contains('c24-uli-back-pwreset-option')) {
                Check24.ul.init("pw", "start");
                return;
            }
            if (methods[0]["method"] === "account_email") {
                Check24.ul.init("pwreset", "email");
            } else if (methods[0]["method"] === "account_sms") {
                Check24.ul.init("pwreset", "phone");
            }
        }
    });

    Check24.addEvent(".c24-uli-back-sq", "click", function() {
        Check24.ul.init("pwreset", "ecode");
    });

    Check24.addEvent(".c24-uli-cl-pwreset-sq-trigger", "click", function() {
        Check24.ul.init("pwreset", "sq");
    });

    Check24.addEvent(".c24-uli-cl-box", "click", function(e) {
        Check24.removeClass(".c24-uli-cl-box", "active");
        e.target.closest(".c24-uli-cl-box").classList.add("active");
        Check24.ul.registerAnonym = false;
    });

    Check24.addEvent(".c24-uli-cl-r-anonym", "click", function() {
        Check24.ul.registerAnonym = true;
    });

    Check24.addEvent(".c24-uli-cl-l-anonymous-trigger", "click", function() {
        var points = Check24.ul.checkPoints();
        if (configService.getEnv() === "default") {
            Check24.ul.postMessage.sendMsg({
                "ul": {
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "anonymous",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": "",
                    "SSO_ENV": "",
                    "SSO_SESSION": "",
                    "TRANSFER_TOKEN": configService.getTransferToken()
                }
            });
        } else {
            var userEleLogin = document.querySelector("#c24-uli-user-login");
            if (userEleLogin) {
                if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                    userEleLogin.value = "phone";
                } else {
                    userEleLogin.value = "email";
                }
            }
            var userEleEmail = document.querySelector("#c24-uli-user-email");
            if (userEleEmail) {
                userEleEmail.value = Check24.ul.email;
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserEmail === "function") {
                C24ulpf.setUserEmail(Check24.ul.email);
            }
            var userElePhone = document.querySelector("#c24-uli-user-phone");
            if (userElePhone) {
                userElePhone.value = Check24.ul.phone;
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPhone === "function") {
                C24ulpf.setUserPhone(Check24.ul.phone);
            }
            var userElePoints = document.querySelector("#c24-uli-user-points");
            if (userElePoints) {
                userElePoints.value = points;
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPoints === "function") {
                C24ulpf.setUserPoints(points);
            }
            var stateEle = document.querySelector("#c24-uli-user-state");
            if (stateEle) {
                stateEle.value = "anonymous";
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.userAnonymous === "function") {
                C24ulpf.userAnonymous();
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
                C24ulpf.getUserData(
                    {
                        "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                        "USERPHONE": Check24.ul.phone,
                        "USEREMAIL": Check24.ul.email,
                        "USERPOINTS": points,
                        "STATE": "anonymous",
                        "VIEW": Check24.ul.view,
                        "LOGINTYPE": Check24.ul.loginPath,
                        "SSO_SERVER": "",
                        "SSO_ENV": "",
                        "SSO_SESSION": "",
                        "TRANSFER_TOKEN": configService.getTransferToken()
                    }
                );
            }
            Check24.ul.server = "";
            Check24.ul.ssoEnv = "";
            Check24.ul.customEvent("anonymous");
        }
        Check24.ul.layout.reset("hard");
    });

    Check24.addEvent(".c24-uli-pw-toggle", "click", function() {
        if (this.classList.contains("cleartext")) {
            Check24.removeClass(".c24-uli-pw-toggle", "cleartext");
            Check24.change("type", ".c24-uli-input-pw", "password");
        } else {
            Check24.addClass(".c24-uli-pw-toggle", "cleartext");
            Check24.change("type", ".c24-uli-input-pw", "text");
        }
    });

    Check24.addEvent(".c24-uli-cl-pw-strength-info-icon", "click", function() {
        var container = this.nextElementSibling;
        if (this.classList.contains("open")) {
            this.classList.remove("open");
            container.style.display = "none";
        } else {
            this.classList.add("open");
            container.style.display = "block";
        }
    });

    Check24.addEvent(".c24-uli-cl-pw-strength-info-close", "click", function() {
        var container = this.parentElement;
        var icon = container.previousElementSibling;
        container.style.display = "none";
        icon.classList.remove("open");
    });

    Check24.addEvent(".c24-uli-back-bb", "click", function() {
        Check24.ul.afterBrowserBack();
    });

    Check24.addEvent(".c24-uli-back-init", "click", function() {
        if (Check24.ul.loginType === "phone") {
            if (Check24.ul.userData.exists === null || (configService.getBrowserBack() === browserBackEnum.ALWAYS && Check24.hasClass(".c24-uli-back-init", "c24-uli-back-browser"))) {
                Check24.ul.afterBrowserBack();
            }
            Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
            if (Check24.ul.userData.exists) {
                if (Check24.ul.userData.loginProvider.includes("google")) {
                    if (Check24.ul.userData.passwordSet === false) {
                        Check24.ul.init("social", "google");
                    } else {
                        Check24.ul.init("pw", "start");
                    }
                } else if (Check24.ul.userData.lastLoginProvider === "facebook" && Check24.ul.userData.passwordSet === false) {
                    Check24.ul.init("social", Check24.ul.userData.lastLoginProvider);
                } else {
                    if (Check24.ul.loginEmail === "other") {
                        Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
                        Check24.ul.loginType = "email";
                        Check24.ul.init("loginOther", "phone");
                    } else if (Check24.ul.loginPhone === "other") {
                        Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                        Check24.ul.loginType = "phone";
                        Check24.ul.init("loginOther", "email");
                    } else {
                        Check24.ul.init("pw", "start");
                    }
                }
            } else {
                if (Check24.ul.loginPhone === "other") {
                    Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                    Check24.ul.loginType = "phone";
                    Check24.ul.init("loginOther", "email");
                } else if (Check24.ul.loginEmail === "other") {
                    Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
                    Check24.ul.loginType = "email";
                    Check24.ul.init("loginOther", "phone");
                } else {
                    Check24.change("value", "#cl_login", Check24.ul.email);
                    Check24.ul.init("login", "nocookie");
                }
            }
        } else {
            if (Check24.ul.userData.exists === null || (configService.getBrowserBack() === browserBackEnum.ALWAYS && Check24.hasClass(".c24-uli-back-init", "c24-uli-back-browser"))) {
                Check24.ul.afterBrowserBack();
            } else {
                if (Check24.ul.userData.exists) {
                    if (Check24.ul.userData.loginProvider.includes("google")) {
                        if (Check24.ul.userData.passwordSet === false) {
                            Check24.ul.init("social", "google");
                        } else {
                            Check24.ul.init("pw", "start");
                        }
                    } else if (Check24.ul.userData.lastLoginProvider === "facebook" && Check24.ul.userData.passwordSet === false) {
                        Check24.ul.init("social", Check24.ul.userData.lastLoginProvider);
                    } else {
                        if (Check24.ul.loginEmail === "other") {
                            Check24.ul.loginType = "email";
                            Check24.ul.init("loginOther", "phone");
                        } else if (Check24.ul.loginPhone === "other") {
                            Check24.ul.loginType = "phone";
                            Check24.ul.init("loginOther", "email");
                        } else {
                            Check24.ul.init("pw", "start");
                        }
                    }
                } else {
                    if (Check24.ul.loginPhone === "other") {
                        Check24.ul.loginType = "phone";
                        Check24.ul.init("loginOther", "email");
                    } else if (Check24.ul.loginEmail === "other") {
                        Check24.ul.loginType = "email";
                        Check24.ul.init("loginOther", "phone");
                    } else {
                        Check24.ul.init("register", "phone");
                    }
                }
            }
        }
    });

    Check24.addEvent(".c24-uli-back-start", "click", function() {
        if (Check24.ul.loginEmail === "other") {
            Check24.ul.userData.superAccount = false;
            Check24.ul.init("loginOther", "phone");
            return false;
        }
        if (Check24.ul.loginPhone === "other") {
            Check24.ul.userData.superAccount = false;
            Check24.ul.init("loginOther", "email");
            return false;
        }
        if (!Check24.ul.userData.superAccount && Check24.ul.registerTriggered && Check24.ul.userData.exists) {
            Check24.ul.init("pw", "start");
            Check24.ul.registerTriggered = false;
            return false;
        }
        Check24.ul.loginPrefill = true;
        Check24.ul.userData.exists = null;
        var login = Check24.getValue("#cl_login_ph");
        Check24.change("value", "#cl_login", login);
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-back-other", "click", function() {
        Check24.ul.loginPrefill = true;
        Check24.ul.userData.exists = null;
        Check24.ul.loginType = "email";
        Check24.ul.changeUser();
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-back", "click", function(e) {
        Check24.ul.pwResetData.mcode = null;
        Check24.ul.pwResetData.method = null;
        if (e.target.classList.contains("c24-uli-back-sld")) {
            Check24.ul.init("setlogindata", "");
            return;
        }
        if (Check24.ul.loginEmail === "other") {
            Check24.ul.init("loginOther", "phone");
            return;
        }
        if (Check24.ul.loginPhone === "other") {
            Check24.ul.init("loginOther", "email");
            return;
        }
        if (Check24.ul.userData.exists) {
            if (Check24.ul.userData.loginProvider.includes("google")) {
                if (Check24.ul.userData.passwordSet === false) {
                    Check24.ul.init("social", "google");
                } else {
                    Check24.ul.init("pw", "start");
                }
            } else if (Check24.ul.userData.lastLoginProvider === "facebook" && Check24.ul.userData.passwordSet === false) {
                Check24.ul.init("social", Check24.ul.userData.lastLoginProvider);
            } else {
                Check24.ul.init("pw", "start");
            }
        } else {
            if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                Check24.ul.init("register", "phone");
            } else {
                Check24.ul.init("register", "email");
            }
        }
    });

    Check24.addEvent(".c24-uli-input-pw", "keyup", function(event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode != '13') {
            if (this.value.length >= Check24.ul.passwordLengthMax) {
                Check24.ul.error.set(".c24-uli-input-pw", ".c24-uli-error-pw", Check24.ul.error.msg.pw.length);
                Check24.ul.error.set(".c24-uli-input-pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.length);
                Check24.ul.error.set(".c24-uli-input-pw-repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.length);
                Check24.ul.error.set(".c24-uli-input-pw-repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.length);
            } else {
                Check24.ul.error.unset(".c24-uli-input-pw", ".c24-uli-error-pw");
                Check24.ul.error.unset(".c24-uli-input-pw", ".c24-uli-error-pwr-pw");
                Check24.ul.error.unset(".c24-uli-input-pw-repeat", ".c24-uli-error-pw-repeat");
                Check24.ul.error.unset(".c24-uli-input-pw-repeat", ".c24-uli-error-pwr-pw-repeat");
            }
        }
    });

    Check24.addEvent(".c24-uli-cl-doi-trigger", "click", function() {
        Check24.ul.pwResetData.zipcode = null;
        Check24.change("value", "#cl_zipcode", "");
        Check24.ul.init("pwreset", "birthday");
    });

    Check24.addEvent(".c24-uli-cl-zipcode-trigger", "click", function() {
        Check24.ul.pwResetData.birthday = null;
        Check24.change("value", "#cl_birthday", "");
        Check24.ul.init("pwreset", "zipcode");
    });

    Check24.addEvent("#cl_birthday", "keydown", function(e) {
        // Prevent entering characters that are not part of a date
        var bdValue = Check24.getValue("#cl_birthday");
        if (e.key !== "Backspace" && e.key !== "Enter") {
            if (isNaN(parseInt(e.key, 10))) {
                e.preventDefault();
                return false;
            }
        }
    });

    Check24.addEvent("#cl_birthday", "keyup", function(event) {
        Check24.bdAc("keyup", event);
    });

    Check24.addEvent("#cl_birthday", "blur", function(event) {
        Check24.bdAc("blur", event);
    });

    Check24.addEvent(".c24-uli-input", "focus", function() {
        Check24.hide([".c24-uli-error"]);
        Check24.removeClass("input","c24-uli-input-error");
        Check24.removeClass(".c24-uli-input-ele","c24-uli-input-error");
        this.parentElement.classList.add("focus");
        Check24.ul.resetHeight();
        Check24.ul.layout.removeSpinner();
    });

    Check24.addEvent(".c24-uli-input", "focusout", function() {
        this.parentElement.classList.remove("focus");
    });

    Check24.addEvent(".c24-uli-input", "keyup", function() {
        if (!this.parentElement.classList.contains('c24-uli-input-ele-nolabel')) {
            if (this.value !== "") {
                this.parentElement.classList.add("active");
            } else {
                this.parentElement.classList.remove("active");
            }
        }
    });

    Check24.addEvent(".c24-uli-radio-points", "change", function() {
        Check24.hide([".c24-uli-points-error"]);
        Check24.removeClass(".c24-uli-points-radio", "c24-uli-error-points");
    });

    Check24.addEvent(".c24-uli-button", "click", function(e) {
        Check24.ul.layout.setSpinner(e, this);
    });

    Check24.addEvent(".c24-uli-cl-r-start-trigger", "click", function() {
        configService.setMode("register");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-cl-l-start-trigger", "click", function() {
        configService.setMode("");
        Check24.ul.init("login", "nocookie");
    });

    Check24.addEvent(".c24-uli-button-sld", "click", function() {
        if (Check24.ul.userPoints === "no" && (configService.getPoints() === "checkbox" || configService.getPoints() === "radio")) {
            Check24.ul.forcePoints = true;
            Check24.ul.init("points", configService.getPoints());
            return false;
        }
        Check24.ul.closeCommunicaton(Check24.ul.loginData);
    });

    Check24.addEvent(".c24-uli-login-code-button", "click", function() {
        try {
            _mtm.push({
                'event': 'uli_submit',
                'uli_context': 'loginprocess',
                'uli_context_view': 'otp',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': Check24.ul.loginType
            });
        } catch (e) {
        }
        Check24.ul.init("loginprocess", "send");
    });

    Check24.addEvent(".c24-uli-input-splitted", "keydown", function(e) {
        let currentValue = e.target.value.toString();

        // Clear input on backspace (8) or delete (46)
        if (e.keyCode === 8 || e.keyCode === 46) {
            e.target.value = "";
        }

        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;

        // Move to previous field (backspace, left arrow) or first field (home)
        if ((e.keyCode === 8 && currentValue === "") || e.keyCode === 36 || e.keyCode === 37) {
            let prev = e.target;
            while ((prev = prev.previousElementSibling) !== null) {
                if (prev.classList && prev.classList.contains("c24-uli-input-splitted")) {
                    prev.focus();
                    if (e.keyCode !== 36) {
                        break;
                    }
                }
            }
        }

        // Move to next field (delete, right arrow) or to last field (end)
        if (e.keyCode === 35 || e.keyCode === 39 || (e.keyCode === 46 && currentValue === "")) {
            let next = e.target;
            while ((next = next.nextElementSibling) !== null) {
                if (next.classList && next.classList.contains("c24-uli-input-splitted")) {
                    next.focus();
                    if (e.keyCode !== 35) {
                        break;
                    }
                }
            }
        }
    });

    Check24.addEvent(".c24-uli-input-splitted", "keyup", function(e) {
        // Clear input on backspace (8) or delete (46)
        if (e.keyCode === 8 || e.keyCode === 46) {
            e.target.value = "";
        }

        let inputFields = e.target.parentElement.querySelectorAll(".c24-uli-input-splitted");
        let hiddenInput = Check24.document.querySelector("#" + e.target.parentElement.dataset.for);
        if (hiddenInput) {
            hiddenInput.value = "";
            for (let i = 0; i < inputFields.length; i++) {
                hiddenInput.value = hiddenInput.value.toString() + inputFields[i].value.toString();
            }
        }
    });

    Check24.addEvent(".c24-uli-input-splitted", "input", function(e) {
        let inputValue = e.target.value.toString();
        e.target.value = inputValue.toUpperCase().slice(-1);

        e.preventDefault();

        if (e.target.value !== "") {
            let next = e.target;
            while ((next = next.nextElementSibling) !== null) {
                if (next.classList && next.classList.contains("c24-uli-input-splitted")) {
                    next.focus();
                    break;
                }
            }
        }

        let inputFields = e.target.parentElement.querySelectorAll(".c24-uli-input-splitted");
        let hiddenInput = Check24.document.querySelector("#" + e.target.parentElement.dataset.for);
        if (hiddenInput) {
            hiddenInput.value = "";
            for (let i = 0; i < inputFields.length; i++) {
                hiddenInput.value = hiddenInput.value.toString() + inputFields[i].value.toString();
            }
        }
    });

    Check24.addEvent(".c24-uli-input-splitted", "paste", function(e) {
        e.preventDefault();

        let pastedText = (e.clipboardData || window.clipboardData).getData('text');
        let code = pastedText.toString().replace(/[^A-Za-z0-9]/g, '').toUpperCase();

        if (code.length === 0) {
            return;
        }

        // Load all input fields
        let inputFields = e.target.parentElement.querySelectorAll(".c24-uli-input-splitted");
        let i, c, pasteStart;
        let emptyFields = 0;

        // Count the number of empty fields after the current one
        if (e.target.value === "") {
            for (i = 0; i < inputFields.length; i++) {
                if (inputFields[i] === e.target) {
                    emptyFields = 1;
                    pasteStart = i;
                } else if (emptyFields > 0 && inputFields[i].value === "") {
                    emptyFields++;
                }
            }
        }
        if (emptyFields < code.length) {
            pasteStart = undefined;
        }

        // Clear all input fields and start paste from beginning
        if (pasteStart === undefined) {
            for (i = 0; i < inputFields.length; i++) {
                inputFields[i].value = "";
            }
            pasteStart = 0;
        }

        i = pasteStart;
        for (c = 0; c < code.length; c++) {
            if (i + c < inputFields.length) {
                inputFields[i + c].value = code.charAt(c);
                inputFields[i + c].focus();
            }
        }

        if (inputFields[i+c]) {
            inputFields[i+c].focus();
        }

        let hiddenInput = Check24.document.querySelector("#" + e.target.parentElement.dataset.for);
        if (hiddenInput) {
            hiddenInput.value = "";
            for (let i = 0; i < inputFields.length; i++) {
                hiddenInput.value = hiddenInput.value.toString() + inputFields[i].value.toString();
            }
        }
    });

    Check24.addEvent(".c24-uli-input-splitted", "focus", function(e) {
        Check24.hide([".c24-uli-error"]);
        Check24.removeClass("input","c24-uli-input-error");
        Check24.ul.resetHeight();
        Check24.ul.layout.removeSpinner();

        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;

        var parentEle = e.target.parentElement;
        if (parentEle && parentEle.dataset && parentEle.dataset.for && !Check24.exists('#' + parentEle.dataset.for)) {
            var hiddenInput = document.createElement('input');
            hiddenInput.id = parentEle.dataset.for;
            hiddenInput.type = 'hidden';
            parentEle.appendChild(hiddenInput);
        }
    });

    Check24.addEvent(".c24-uli-tan-resend", "click", function() {
        Check24.ul.showResetTanCodeText(Check24.ul.tanData.method);
        if (Check24.ul.tanResendTimer === null) {
            Check24.ul.ajax.tanNew(Check24.ul.tanData, Check24.ul.callback.tanNew);
            Check24.ul.initTanResendTimeout();
            Check24.change("value", ".c24-uli-tan .c24-uli-input-splitted", "");
        }
    });

    Check24.addEvent(".c24-uli-pwreset-resend", "click", function() {
        Check24.ul.showResetTanCodeText(Check24.ul.pwResetMode);
        if (Check24.ul.tanResendTimer === null) {
            Check24.ul.pwResetData.mcode = null;
            Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
            Check24.ul.initTanResendTimeout();
            Check24.change("value", ".c24-uli-pwr-mtan .c24-uli-input-splitted", "");
            Check24.change("value", ".c24-uli-pwr-etan .c24-uli-input-splitted", "");
        }
    });

    Check24.addEvent(".c24-uli-lc-resend", "click", function() {
        Check24.ul.showResetTanCodeText(Check24.ul.loginChallengeData.type);
        if (Check24.ul.tanResendTimer === null) {
            Check24.ul.ajax.loginChallengeStart(Check24.ul.loginChallengeData.id, Check24.ul.loginChallengeData.type, Check24.ul.loginChallengeData.login, Check24.ul.loginChallengeData.loginType, Check24.ul.callback.loginChallengeStart);
            Check24.ul.initTanResendTimeout();
            Check24.change("value", ".c24-uli-lc-code .c24-uli-input-splitted", "");
        }
    });

    Check24.addEvent(".c24-uli-logintan-resend", "click", function() {
        var login = Check24.getValue("#cl_login_ph");
        var loginProvider = "check24_tan_email";
        var tanMode = "email";
        if (Check24.ul.userData.loginProviderOTP === "check24_tan_phone") {
            loginProvider = "check24_tan_phone";
            tanMode = "sms";
        }
        Check24.ul.showResetTanCodeText(tanMode);
        if (Check24.ul.tanResendTimer === null) {
            Check24.ul.ajax.loginProcess(login, loginProvider, Check24.ul.callback.loginProcess);
            Check24.ul.initTanResendTimeout();
            Check24.change("value", ".c24-uli-loginprocess-tan .c24-uli-input-splitted", "");
        }
    });

    Check24.addEvent(".c24-uli-lc-birthday-trigger", "click", function() {
        Check24.ul.loginChallengeData.zipcode = null;
        Check24.change("value", "#cl_zipcode_lc", "");
        Check24.ul.init("loginchallenge", "birthday");
    });

    Check24.addEvent(".c24-uli-lc-zipcode-trigger", "click", function() {
        Check24.ul.loginChallengeData.birthday = null;
        Check24.change("value", "#cl_birthday_lc", "");
        Check24.ul.init("loginchallenge", "zipcode");
    });

    Check24.addEvent(".c24-uli-mfa-other", "click", function() {
        Check24.ul.init("mfasq", "");
    });

    Check24.addEvent(".c24-uli-choose-radio-ele", "click", function(e){
        var target = e.target.closest(".c24-uli-choose-radio-ele");
        Check24.ul.pwResetData.method = target.dataset.method;
        Check24.ul.pwResetData.recipient = target.querySelector(".c24-uli-choose-account").innerHTML;
    });

    Check24.addEvent(".c24-uli-pwr-pw-link", "click", function() {
        Check24.ul.init("pwreset", "pw");
    });

    Check24.addEvent(".c24-uli-button-sld-later", "click", function() {
        Check24.setStorageData("ul_sld", Date.now());
    });

    Check24.addEvent('.c24-uli-form', 'submit', function(e) {
        var mtmData = {
            'event': 'uli_submit',
            'uli_context': Check24.ul.state,
            'uli_context_view': Check24.ul.stateOption,
            'uli_api_product': configService.getApiProduct(),
            'uli_sso_env': configService.getSsoEnv(),
            'uli_login_type': Check24.ul.loginType
        };
        if (Check24.ul.state === "pwreset") {
            mtmData.uli_pwr_context = Check24.ul.pwResetData.method;
            if (Check24.ul.stateOption === "ecode") {
                if (Check24.ul.pwResetData.method === "account_sms") {
                    mtmData.uli_pwr_context = "account_email";
                } else {
                    mtmData.uli_pwr_context = "account_sms";
                }
            }
        }
        if (Check24.ul.state === "points") {
            mtmData.uli_points = Check24.ul.checkPoints();
        }
        try {
            if (window._mtm) {
                _mtm.push(mtmData);
            }
        } catch (e) {
        }
    });
    try {
        if (window._mtm) {
            _mtm.push({
                'event': 'uli_view',
                'uli_context': 'process',
                'uli_context_view': 'start',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': Check24.ul.loginType
            });
        }
    } catch (e) {
    }
}