import {configService} from "./services/configService";
import {csrfTokenService} from "./services/csrfTokenService";

export function sendRequest(path, params, callback, callbackError, timeout, callbackTimeout, lastRequest) {
    if (window.Check24Uli.ul.requestRunning) {
        return;
    }

    window.Check24Uli.ul.requestRunning = true;

    const uri = (configService.getBasePathTest() !== "" ? configService.getBasePathTest() : configService.getBasePath()) + path;

    if (process.env.ENVIRONMENT !== "production" && !params.hasOwnProperty("sso_env")) {
        params.sso_env = configService.getSsoEnv();
    }

    if (process.env.ENVIRONMENT !== "production" && !params.hasOwnProperty("sso_core")) {
        params.sso_core = configService.getSsoCore();
    }

    params.product = configService.getApiProduct();
    params.config_key = configService.getConfigKey();

    params.challenge = configService.getChallenge();
    params.uli_context = configService.getContext();
    params.csrf_token = csrfTokenService.getToken();
    params.csrf_id = csrfTokenService.getIdentifier();
    params.csrf_validation = configService.getCsrfValidation();
    params.deviceoutput = configService.getDeviceoutput();

    params.entrypoint = window.Check24Uli.ul.userData.loginType;
    params.account_exists = window.Check24Uli.ul.userData.initialExisted;
    if (window.Check24Uli.ul.clientHintsFound) {
        params.client_hints = JSON.stringify(window.Check24Uli.ul.userData.clientHints);
    }

    if (configService.getCookieDomain() !== null) {
        params.cookie_domain = configService.getCookieDomain();
    }

    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
        window.Check24Uli.ul.requestRunning = false;
        if (xhr.status >= 200 && xhr.status < 300) {
            let responseData = JSON.parse(this.responseText);
            if (!lastRequest && responseData && responseData.status && responseData.status === 499) {
                // Invalid CSRF token
                window.Check24Uli.ul.ajax.csrfToken(function () {
                    sendRequest(path, params, callback, callbackError, timeout, callbackTimeout, true);
                });
            } else if (!lastRequest && responseData && responseData.status && responseData.status === 429) {
                // Delay request due to flood protection
                window.setTimeout(function () {
                    sendRequest(path, params, callback, callbackError, timeout, callbackTimeout, true);
                }, 1050);
            } else if (!lastRequest && responseData && ((responseData.status && responseData.status === 405) || (responseData.a && responseData.a === 405))) {
                // Invalid Captcha
                Check24.ul.useCaptcha = true;
                Check24.ul.initCaptcha(function() {
                    sendRequest(path, params, callback, callbackError, timeout, callbackTimeout)
                });
            } else if (typeof callback === 'function') {
                csrfTokenService.parseXhrResponse(this.responseText);
                callback(this.responseText);
            }
        } else {
            if (typeof callbackError === 'function') {
                callbackError();
            }
            window.Check24Uli.ul.globalError();
        }
    };

    xhr.onerror = function () {
        window.Check24Uli.ul.globalError();
        window.Check24Uli.ul.requestRunning = false;
    };

    xhr.open('POST', uri, true);

    if (timeout) {
        xhr.timeout = timeout;
        xhr.ontimeout = function (e) {
            if (typeof callbackTimeout === 'function') {
                callbackTimeout(e);
            }
        }
    }

    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.withCredentials = true;
    xhr.send(serialize(params));
}

/**
 * Serialize an object into a query string. Via https://stackoverflow.com/a/1714899/3327577
 * Notable difference to the StackOverflow answer: It does NOT encode the URL components
 *
 * @param {Object.<string, string>} obj
 * @returns {string}
 */
function serialize (obj) {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
            str.push(p + "=" + obj[p]);
        }
    return str.join("&");
}
