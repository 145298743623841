import {sanitizer} from "../helpers/sanitizer";
import {configService} from "../services/configService";
import {poJD} from "../po/po.js_desktop";
import Check24 from "../unifiedlogin_desktop";

export default {
    loginListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        var login = Check24.getValue("#cl_login");
        if (login === '') {
            // set noValue-error and return
            Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.noValue.emailOrPhone);
            return;
        }

        // check type of user input
        var type = Check24.getValueType(login);
        Check24.ul.userData.loginType = type;
        if (type === 'phone') {
            Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
            if (Check24.validate.phone(login)) {
                Check24.ul.ajax.convertMobile(login, "", Check24.ul.callback.convertMobile);
            } else {
                Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email_or_phone);
            }
        } else {
            Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
            if (Check24.validate.email(login)) {
                Check24.change("value", "#cl_login_ph", login);
                Check24.change("html", ".c24-uli-user-data", sanitizer.html(login));
                Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(login));
                Check24.ul.userData.exists = null;
                Check24.ul.ajax.exists(login, "email", Check24.ul.userData, Check24.ul.callback.exists);
            } else {
                Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email);
            }
        }
    },
    pwListener: function(e) {
        e.preventDefault();
        Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-pw-btn"));
        Check24.ul.error.unset();
        var login = Check24.getValue("#cl_login_ph");
        var pw = Check24.getValue("#cl_pw_login");
        Check24.change("type", ".c24-uli-input-pw", "password");
        Check24.removeClass(".c24-uli-pw-toggle", "cleartext");
        if (pw === "") {
            Check24.ul.error.set('#cl_pw_login', '.c24-uli-error-pw', Check24.ul.error.msg.noValue.pw);
            return false;
        }
        Check24.ul.userPassword = pw;
        Check24.ul.ajax.login(login, pw, Check24.ul.loginType, "", "", Check24.ul.userData, undefined, undefined, Check24.ul.callback.login, undefined);
    },
    loginOtherListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
            var login = Check24.getValue("#cl_email_login_other");
            if (login !== '') {
                if (Check24.validate.email(login)) {
                    Check24.change("value", "#cl_login_ph", login);
                    Check24.change("html", ".c24-uli-user-data", sanitizer.html(login));
                    Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(login));
                    Check24.ul.userData.exists = null;
                    Check24.ul.ajax.exists(login, "email", Check24.ul.userData, Check24.ul.callback.exists);
                } else {
                    Check24.ul.error.set('#cl_email_login_other', '.c24-uli-error-email', Check24.ul.error.msg.validate.email);
                }
            } else {
                Check24.ul.error.set('#cl_email_login_other', '.c24-uli-error-email', Check24.ul.error.msg.noValue.email);
            }
        } else {
            var login = Check24.getValue("#cl_phone_login_other");
            if (login !== '') {
                if (Check24.validate.phone(login)) {
                    Check24.ul.ajax.convertMobile(login, "", Check24.ul.callback.convertMobile);
                } else {
                    Check24.ul.error.set('#cl_phone_login_other', '.c24-uli-error-email', Check24.ul.error.msg.validate.phone);
                }
            } else {
                Check24.ul.error.set('#cl_phone_login_other', '.c24-uli-error-email', Check24.ul.error.msg.noValue.phone);
            }
        }
    },
    googleListener: function(e) {
        e.preventDefault();
        var url = configService.getSocialLoginBaseUrl() + "user/oauth/login.html?oauth_provider=google" +
            "&callback=" + encodeURIComponent(configService.getSocialLoginCallbackUrl()) +
            "&closing=" + encodeURIComponent(configService.getSocialLoginClosingUrl()) +
            "&product=" + encodeURIComponent(configService.getApiProduct());
        var viewportHeight,
            viewportWidth,
            slPopup;
        if (document.compatMode === "BackCompat") {
            viewportHeight = document.body.clientHeight;
            viewportWidth = document.body.clientWidth;
        } else {
            viewportHeight = document.documentElement.clientHeight;
            viewportWidth = document.documentElement.clientWidth;
        }
        slPopup = window.open(url, 'Anmelden', 'width=520, height=530, scrollbars=yes, status=no, titlebar=no, top=' + ((window.screenY + (viewportHeight / 2)) - 280) + ', left=' + ((window.screenX + (viewportWidth / 2)) - 265));
        slPopup.focus();
    },
    socialListener: function(e) {
        e.preventDefault();
        Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
        Check24.ul.initTanResendTimeout();
    },
    registerListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        var login = Check24.getValue("#cl_login_ph");
        var pw = Check24.getValue("#cl_pw_register");
        var pwRepeat =  Check24.getValue("#cl_ul_pw_register_repeat");
        var firstname =  Check24.getValue("#cl_ul_firstname");
        var lastname =  Check24.getValue("#cl_ul_lastname");
        var nameValidate = true;
        var pwShortLong = false;
        var pwRepeatShortLong = false;
        if (!Check24.ul.registerAnonym) {
            if (configService.getContext() === "loginlayer" || configService.getContext() === "kb" || configService.getRegisterUsername() === "true") {
                if (firstname === "") {
                    nameValidate = false;
                    Check24.ul.error.set('#cl_ul_firstname', '.c24-uli-error-firstname', Check24.ul.error.msg.noValue.firstname);
                    Check24.ul.resetHeight();
                }
                if (lastname === "") {
                    nameValidate = false;
                    Check24.ul.error.set('#cl_ul_lastname', '.c24-uli-error-lastname', Check24.ul.error.msg.noValue.lastname);
                    Check24.ul.resetHeight();
                }
            }
            if (pw === "" && pwRepeat === "") {
                Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.noValue.pw);
                Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.noValue.pw);
                Check24.ul.resetHeight();
                return false;
            }
            if (pw.length < Check24.ul.passwordLengthMin) {
                Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.pw.shortPw);
                Check24.ul.resetHeight();
                pwShortLong = true;
            }
            if (pwRepeat.length < Check24.ul.passwordLengthMin) {
                Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.shortPw);
                Check24.ul.resetHeight();
                pwRepeatShortLong = true;
            }
            if (pw.length > Check24.ul.passwordLengthMax) {
                Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.pw.length);
                Check24.ul.resetHeight();
                pwShortLong = true;
            }
            if (pwRepeat.length > Check24.ul.passwordLengthMax) {
                Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.length);
                Check24.ul.resetHeight();
                pwRepeatShortLong = true;
            }
            if (configService.getPwMode() === "strong") {
                if (!Check24.PasswordStrength.passwordCheck.checkAll(pw, login)) {
                    Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.pw.strong);
                    Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.strong);
                    Check24.ul.resetHeight();
                    return false;
                }
            }
            if (pw === "" || pwRepeat === "") {
                if (pw === "") {
                    Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.pw.repeatEmpty);
                }
                if (pwRepeat === "") {
                    Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.repeatEmpty);
                }
                Check24.ul.resetHeight();
                return false;
            }

            if (!Check24.ul.pwRepeat("#cl_pw_register", "#cl_ul_pw_register_repeat")) {
                if (!pwShortLong) {
                    Check24.ul.error.set('#cl_pw_register', '.c24-uli-error-pw', Check24.ul.error.msg.pw.repeat);
                }
                if (!pwRepeatShortLong) {
                    Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.repeat);
                }
                Check24.ul.resetHeight();
                return false;
            }
            if (pwShortLong || pwRepeatShortLong) {
                return false;
            }
            if (!nameValidate) {
                return false;
            }
            if (Check24.ul.pwRepeat("#cl_pw_register", "#cl_ul_pw_register_repeat")) {
                if (Check24.ul.registerMode === "secure") {
                    Check24.ul.tanData.id = login;
                    Check24.ul.tanData.pw = pw;
                    Check24.ul.tanData.firstname = firstname;
                    Check24.ul.tanData.lastname = lastname;
                    Check24.ul.tanData.context = "user_new";
                    Check24.ul.tanContext = "new";
                    Check24.ul.tanData.method = "email";
                    if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                        Check24.ul.tanData.method = "sms";
                    }
                    Check24.ul.init("tan", "new");
                } else {
                    Check24.ul.ajax.register(login, pw, firstname, lastname, Check24.ul.loginType, Check24.ul.callback.register);
                }
            }
            Check24.ul.resetHeight();
        } else {
            var points = Check24.ul.checkPoints();
            if (configService.getEnv() === "default") {
                Check24.ul.postMessage.sendMsg({
                    "ul": {
                        "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                        "USERPHONE": Check24.ul.phone,
                        "USEREMAIL": Check24.ul.email,
                        "USERPOINTS": points,
                        "STATE": "anonymous",
                        "VIEW": Check24.ul.view,
                        "LOGINTYPE": Check24.ul.loginPath,
                        "SSO_SERVER": "",
                        "SSO_ENV": "",
                        "SSO_SESSION": "",
                        "TRANSFER_TOKEN": configService.getTransferToken()
                    }
                });
            } else {
                var userEleLogin = document.querySelector("#c24-uli-user-login");
                if (userEleLogin) {
                    if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                        userEleLogin.value = "phone";
                    } else {
                        userEleLogin.value = "email";
                    }
                }
                var userEleEmail = document.querySelector("#c24-uli-user-email");
                if (userEleEmail) {
                    userEleEmail.value = Check24.ul.email;
                }
                if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserEmail === "function") {
                    C24ulpf.setUserEmail(Check24.ul.email);
                }
                var userElePhone = document.querySelector("#c24-uli-user-phone");
                if (userElePhone) {
                    userElePhone.value = Check24.ul.phone;
                }
                if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPhone === "function") {
                    C24ulpf.setUserPhone(Check24.ul.phone);
                }
                var userElePoints = document.querySelector("#c24-uli-user-points");
                if (userElePoints) {
                    userElePoints.value = points;
                }
                if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPoints === "function") {
                    C24ulpf.setUserPoints(points);
                }
                var stateEle = document.querySelector("#c24-uli-user-state");
                if (stateEle) {
                    stateEle.value = "anonymous";
                }
                if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.userAnonymous === "function") {
                    C24ulpf.userAnonymous();
                }
                if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
                    C24ulpf.getUserData(
                        {
                            "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                            "USERPHONE": Check24.ul.phone,
                            "USEREMAIL": Check24.ul.email,
                            "USERPOINTS": points,
                            "STATE": "anonymous",
                            "VIEW": Check24.ul.view,
                            "LOGINTYPE": Check24.ul.loginPath,
                            "SSO_SERVER": "",
                            "SSO_ENV": "",
                            "SSO_SESSION": "",
                            "TRANSFER_TOKEN": configService.getTransferToken()
                        }
                    );
                }
                Check24.ul.server = "";
                Check24.ul.ssoEnv = "";
                Check24.ul.customEvent("anonymous");
            }
            Check24.ul.layout.reset("hard");
        }
    },
    registercheckListener: function(e) {
        e.preventDefault();
        Check24.ul.registercheckTriggered = true;
        Check24.ul.error.unset();

        var login = Check24.getValue("#cl_email_registercheck");
        if (login === '') {
            Check24.ul.error.set('#cl_email_registercheck', '.c24-uli-error-registercheck', Check24.ul.error.msg.noValue.email);
            return;
        }
        if (Check24.validate.email(login)) {
            Check24.ul.userData.email = login;
            Check24.ul.userData.exists = null;
            Check24.ul.ajax.exists(login, "email", Check24.ul.userData, Check24.ul.callback.exists);
        } else {
            Check24.ul.error.set('#cl_email_registercheck', '.c24-uli-error-registercheck', Check24.ul.error.msg.validate.email);
        }
    },
    pwResetListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        Check24.ul.hideResentTanCodeText();
        switch (Check24.ul.pwResetMode) {
            case "choose":
                Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                Check24.ul.initTanResendTimeout();
                break;
            case "email":
                Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                Check24.ul.initTanResendTimeout();
                break;
            case "phone":
                Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                Check24.ul.initTanResendTimeout();
                break;
            case "mcode":
                var mtan = Check24.exists("#cl_mtan") ? Check24.getValue("#cl_mtan") : "";
                if (mtan) {
                    if (Check24.validate.mcode(mtan)) {
                        Check24.ul.pwResetData.mcode = mtan;
                        Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-pwr-mtan-btn"));
                        Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                    } else {
                        Check24.ul.error.set(".c24-uli-pwr-mtan .c24-uli-input-splitted", ".c24-uli-error-mtan", Check24.ul.error.msg.pwReset.wrongCode);
                    }
                } else {
                    Check24.ul.error.set(".c24-uli-pwr-mtan .c24-uli-input-splitted", ".c24-uli-error-mtan", Check24.ul.error.msg.pwReset.noCode);
                }
                break;
            case "ecode":
                var etan = Check24.exists("#cl_etan") ? Check24.getValue("#cl_etan") : "";
                if (etan) {
                    if (Check24.validate.ecode(etan)) {
                        Check24.ul.pwResetData.ecode = etan;
                        Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-pwr-etan-btn"));
                        Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                    } else {
                        Check24.ul.error.set(".c24-uli-pwr-etan .c24-uli-input-splitted", ".c24-uli-error-etan", Check24.ul.error.msg.pwReset.wrongCode);
                    }
                } else {
                    Check24.ul.error.set(".c24-uli-pwr-etan .c24-uli-input-splitted", ".c24-uli-error-etan", Check24.ul.error.msg.pwReset.noCode);
                }
                break;
            case "sq":
                var sq = Check24.getValue("#cl_sq");
                if (sq) {
                    Check24.ul.pwResetData.sq = sq;
                    Check24.ul.ajax.sqValidate(Check24.ul.pwResetData, Check24.ul.sqCurrent, Check24.ul.loginType, Check24.ul.callback.sqValidate);
                } else {
                    Check24.ul.error.set("#cl_sq", ".c24-uli-error-sq", Check24.ul.error.msg.noValue.sq);
                }
                break;
            case "zipcode":
                var zipcode = Check24.getValue("#cl_zipcode");
                if (zipcode) {
                    Check24.ul.pwResetData.zipcode = zipcode;
                    Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                } else {
                    Check24.ul.error.set("#cl_zipcode", ".c24-uli-error-zipcode", Check24.ul.error.msg.pwReset.noZipcode);
                }
                break;
            case "birthday":
                var birthday = Check24.getValue("#cl_birthday");
                var bdc = birthday.split(".");
                if (birthday !== "") {
                    if (bdc[0] && bdc[1] && bdc[2]) {
                        if (!isNaN(parseFloat(bdc[0])) && isFinite(bdc[0]) &&
                            !isNaN(parseFloat(bdc[1])) && isFinite(bdc[1]) &&
                            !isNaN(parseFloat(bdc[2])) && isFinite(bdc[2])) {
                            if ((bdc[0] < 1 || bdc[0] > 31) || (bdc[1] < 1 || bdc[1] > 12) || (bdc[2] < 1800 || bdc[2] > 2300)) {
                                Check24.ul.error.set("#cl_birthday", ".c24-uli-error-birthday", Check24.ul.error.msg.pwReset.falseBirthday);
                                return false;
                            }
                        } else {
                            Check24.ul.error.set("#cl_birthday", ".c24-uli-error-birthday", Check24.ul.error.msg.pwReset.falseBirthday);
                            return false;
                        }
                    } else {
                        Check24.ul.error.set("#cl_birthday", ".c24-uli-error-birthday", Check24.ul.error.msg.pwReset.falseBirthday);
                        return false;
                    }
                } else {
                    Check24.ul.error.set("#cl_birthday", ".c24-uli-error-birthday", Check24.ul.error.msg.pwReset.noBirthday);
                    return false;
                }
                if (bdc[0].length === 1) {
                    bdc[0] = "0" + bdc[0];
                }
                if (bdc[1].length === 1) {
                    bdc[1] = "0" + bdc[1];
                }
                var bd = bdc[2] + "-" + bdc[1] + "-" + bdc[0];
                Check24.ul.pwResetData.birthday = bd;
                Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                break;
            case "pw":
                var login = Check24.getValue("#cl_login_ph");
                var pw = Check24.getValue("#cl_pwr_pw");
                var pwRepeat = Check24.getValue("#cl_pwr_pw_repeat");
                var pwShortLong = false;
                var pwRepeatShortLong = false;
                if (pw === "" && pwRepeat === "") {
                    Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.noValue.pw);
                    Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.noValue.pw);
                    Check24.ul.resetHeight();
                    return false;
                }
                if (pw.length < Check24.ul.passwordLengthMin) {
                    Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.shortPw);
                    Check24.ul.resetHeight();
                    pwShortLong = true;
                }
                if (pwRepeat.length < Check24.ul.passwordLengthMin) {
                    Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.shortPw);
                    Check24.ul.resetHeight();
                    pwRepeatShortLong = true;
                }
                if (pw.length > Check24.ul.passwordLengthMax) {
                    Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.length);
                    Check24.ul.resetHeight();
                    pwShortLong = true;
                }
                if (pwRepeat.length > Check24.ul.passwordLengthMax) {
                    Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.length);
                    Check24.ul.resetHeight();
                    pwRepeatShortLong = true;
                }
                if (configService.getPwMode() === "strong") {
                    if (!Check24.PasswordStrength.passwordCheck.checkAll(pw, login)) {
                        Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.strong);
                        Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.strong);
                        Check24.ul.resetHeight();
                        return false;
                    }
                }
                if (pw === "" || pwRepeat === "") {
                    if (pw === "") {
                        Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.repeatEmpty);
                    }
                    if (pwRepeat === "") {
                        Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.repeatEmpty);
                    }
                    Check24.ul.resetHeight();
                    return false;
                }
                if (!Check24.ul.pwRepeat("#cl_pwr_pw", "#cl_pwr_pw_repeat")) {
                    if (!pwShortLong) {
                        Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.repeat);
                    }
                    if (!pwRepeatShortLong) {
                        Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pwr-pw-repeat", Check24.ul.error.msg.pw.repeat);
                    }
                    Check24.ul.resetHeight();
                    return false;
                }
                if (pwShortLong || pwRepeatShortLong) {
                    return false;
                }
                Check24.ul.pwResetData.password = pw;
                Check24.ul.ajax.login(login, pw, Check24.ul.loginType, "", "", Check24.ul.userData, undefined, undefined, Check24.ul.callback.pwResetLogin, undefined);
                break;
            case "login":
                Check24.ul.pwResetData.loginInstead = "yes";
                Check24.ul.ajax.pwResetPw(Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwResetLoginInstead);
                break;
            default:
                console.log("No valid state given");
                Check24.ul.init("login", "");
        }
        Check24.ul.resetHeight();
    },
    renewListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        Check24.ul.hideResentTanCodeText()
        switch (Check24.ul.renewMode) {
            case "start":
                var login = Check24.getValue("#cl_login_ph");
                Check24.ul.tanData.id = login;
                Check24.ul.tanData.context = Check24.ul.userData.superAccount ? "user_deactivate" : "user_new";
                if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                    Check24.ul.tanData.method = "sms";
                } else {
                    Check24.ul.tanData.method = "email";
                }
                Check24.ul.tanContext = "renew";
                Check24.ul.init("tan", "new");
                break;
            case "code":
                var tan = sanitizer.tan(Check24.getValue("#cl_renew_tan"));
                if (tan !== "") {
                    if (Check24.validate.mcode(tan)) {
                        Check24.ul.tanData.code = tan;
                        Check24.ul.ajax.tanValidate(Check24.ul.tanData, Check24.ul.callback.tanValidate);
                    }
                } else {
                    Check24.ul.error.set("#cl_renew_tan", ".c24-uli-error-renew-tan", Check24.ul.error.msg.pwReset.noCode);
                }
                break;
            case "pw":
                var login = Check24.getValue("#cl_login_ph");
                var pw = Check24.getValue("#cl_pw_renew");
                var pwRepeat = Check24.getValue("#cl_pw_renew_repeat");
                var firstname =  Check24.getValue("#cl_ul_firstname_renew");
                var lastname =  Check24.getValue("#cl_ul_lastname_renew");
                var nameValidate = true;
                var pwShortLong = false;
                var pwRepeatShortLong = false;
                if (configService.getContext() === "loginlayer" || configService.getContext() === "kb" || configService.getRegisterUsername() === "true") {
                    if (firstname === "") {
                        nameValidate = false;
                        Check24.ul.error.set('#cl_ul_firstname_renew', '.c24-uli-error-firstname', Check24.ul.error.msg.noValue.firstname);
                        Check24.ul.resetHeight();
                    }
                    if (lastname === "") {
                        nameValidate = false;
                        Check24.ul.error.set('#cl_ul_lastname_renew', '.c24-uli-error-lastname', Check24.ul.error.msg.noValue.lastname);
                        Check24.ul.resetHeight();
                    }
                }
                if (pw === "" && pwRepeat === "") {
                    Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.noValue.pw);
                    Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.noValue.pw);
                    Check24.ul.resetHeight();
                }
                if (pw.length < Check24.ul.passwordLengthMin) {
                    Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.shortPw);
                    Check24.ul.resetHeight();
                    pwShortLong = true;
                }
                if (pwRepeat.length < Check24.ul.passwordLengthMin) {
                    Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.shortPw);
                    Check24.ul.resetHeight();
                    pwRepeatShortLong = true;
                }
                if (pw.length > Check24.ul.passwordLengthMax) {
                    Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.length);
                    Check24.ul.resetHeight();
                    pwShortLong = true;
                }
                if (pwRepeat.length > Check24.ul.passwordLengthMax) {
                    Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.length);
                    Check24.ul.resetHeight();
                    pwRepeatShortLong = true;
                }
                if (configService.getPwMode() === "strong") {
                    if (!Check24.PasswordStrength.passwordCheck.checkAll(pw, login)) {
                        Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.strong);
                        Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.strong);
                        Check24.ul.resetHeight();
                        return false;
                    }
                }
                if (pw === "" || pwRepeat === "") {
                    if (pw === "") {
                        Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.repeatEmpty);
                    }
                    if (pwRepeat === "") {
                        Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.repeatEmpty);
                    }
                    Check24.ul.resetHeight();
                    return false;
                }
                if (!Check24.ul.pwRepeat("#cl_pw_renew", "#cl_pw_renew_repeat")) {
                    if (!pwShortLong) {
                        Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.repeat);
                    }
                    if (!pwRepeatShortLong) {
                        Check24.ul.error.set("#cl_pw_renew_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.repeat);
                    }
                    Check24.ul.resetHeight();
                    return false;
                }
                if (pwShortLong || pwRepeatShortLong) {
                    return false;
                }
                if (!nameValidate) {
                    return false;
                }

                Check24.ul.tanData.id = login;
                Check24.ul.tanData.pw = pw;
                Check24.ul.tanData.firstname = firstname;
                Check24.ul.tanData.lastname = lastname;
                if (Check24.ul.userData.superAccount) {
                    Check24.ul.ajax.deactivate(Check24.ul.tanData, Check24.ul.callback.deactivate);
                } else {
                    Check24.ul.tanData.consecutiveTan = Check24.ul.tanData.code;
                    Check24.ul.ajax.userNew(Check24.ul.tanData, Check24.ul.tanContext, '', Check24.ul.callback.userNew);
                }
                break;
        }
        Check24.ul.resetHeight();
    },
    tanValidateListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        Check24.ul.hideResentTanCodeText();
        var tan = Check24.exists("#cl_tan") ? Check24.getValue("#cl_tan") : "";
        if (tan === "") {
            Check24.ul.error.set('.c24-uli-tan .c24-uli-input-splitted', '.c24-uli-error-tan', Check24.ul.error.msg.noValue.code);
            Check24.ul.resetHeight();
            return false;
        }
        if (Check24.validate.mcode(tan)) {
            Check24.ul.tanData.code = tan;
            Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-tan-btn"));
            Check24.ul.ajax.tanValidate(Check24.ul.tanData, Check24.ul.callback.tanValidate);
        } else {
            Check24.ul.error.set('.c24-uli-tan .c24-uli-input-splitted', '.c24-uli-error-tan', Check24.ul.error.msg.pwReset.wrongCode);
        }
        Check24.ul.resetHeight();
    },
    pointsActionListener: function(e) {
        e.preventDefault();
        Check24.removeClass(".c24-uli-points-radio", "c24-uli-error-points");
        Check24.hide(".c24-uli-points-error");
        if (!Check24.ul.validatePoints()) {
            Check24.addClass(".c24-uli-points-radio", "c24-uli-error-points");
            Check24.show(".c24-uli-points-error");
            Check24.ul.resetHeight();
            Check24.ul.layout.removeSpinner();
            return false;
        } else {
            var points = Check24.ul.checkPoints();
            var pointsRegister = (points === "yes");
            if (pointsRegister) {
                Check24.ul.ajax.pointsRegister(
                    pointsRegister,
                    Check24.ul.closeCommunicaton,
                    Check24.ul.closeCommunicaton,
                    1000,
                    Check24.ul.closeCommunicaton
                );
            } else {
                Check24.ul.closeCommunicaton();
            }
        }
    },
    setLoginDataListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        var prefixPhone = Check24.getValue("#cl_sld_prefix");
        var phoneNumber = Check24.getValue("#cl_sld");

        if (prefixPhone === "" || phoneNumber === "") {
            Check24.ul.error.set('#cl_sld_prefix', '.c24-uli-error-sld', '');
            Check24.ul.error.set('#cl_sld', '.c24-uli-error-sld', Check24.ul.error.msg.noValue.phone);
            Check24.ul.resetHeight();
            return false;
        } else {
            var mobileNumber = prefixPhone + phoneNumber;
            Check24.ul.ajax.convertMobile(mobileNumber, "", Check24.ul.callback.convertMobileSld);
        }
    },
    mfasqListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        var prefixPhone = Check24.getValue("#cl_mfasq_prefix");
        var phoneNumber = Check24.getValue("#cl_mfasq");

        if (prefixPhone === "" || phoneNumber === "") {
            Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', '');
            Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.noValue.phone);
            Check24.ul.resetHeight();
            return false;
        } else {
            var mobileNumber = prefixPhone + phoneNumber;
            Check24.ul.ajax.convertMobile(mobileNumber, "", Check24.ul.callback.convertMobileMfasq);
        }
    },
    loginChallengeStartListener: (e) => {
        e.preventDefault();
        Check24.ul.hideResentTanCodeText();
        if (Check24.ul.loginChallengeData.type.indexOf("email") !== -1) {
            Check24.show(['.c24-uli-input-info-text-email', '.c24-uli-lc-text-email']);
            Check24.change("html", "#c24-uli-lc-tan-label", poJD.tan.enter_code_email);
        } else {
            Check24.show('.c24-uli-lc-text-phone');
            Check24.change("html", "#c24-uli-lc-tan-label", poJD.tan.enter_code_sms);
        }

        Check24.ul.loginChallengeData.login = Check24.getValue("#cl_login_ph");
        Check24.ul.loginChallengeData.loginType = "email";
        if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
            Check24.ul.loginChallengeData.loginType = "phone";
        }
        Check24.ul.ajax.loginChallengeStart(Check24.ul.loginChallengeData.id, Check24.ul.loginChallengeData.type, Check24.ul.loginChallengeData.login, Check24.ul.loginChallengeData.loginType, Check24.ul.callback.loginChallengeStart);
        Check24.ul.initTanResendTimeout();
    },
    loginChallengeSubmitListener: (e) => {
        e.preventDefault();
        Check24.ul.loginChallengeData.response = Check24.exists("#c24-uli-cl_lc") ? Check24.getValue('#c24-uli-cl_lc').trim() : "";

        if (!Check24.ul.loginChallengeData.response) {
            Check24.show('.c24-uli-cl_lc-error-noval');
            Check24.ul.error.set('.c24-uli-loginchallenge .c24-uli-input-splitted');
            Check24.ul.layout.removeSpinner();
            return;
        }

        var login = Check24.getValue("#cl_login_ph");
        var pw = Check24.ul.userPassword;

        Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-lc-code-btn"));

        var enableMfa = true;
        var disableMfaInput = Check24.document.querySelector("#c24-uli-disable-mfa");
        if (disableMfaInput.checked === true) {
            enableMfa = false;
        }

        Check24.ul.ajax.login(
            login,
            pw,
            Check24.ul.loginType,
            Check24.ul.tanData.code,
            "",
            Check24.ul.userData,
            Check24.ul.loginChallengeData.id,
            Check24.ul.loginChallengeData.response,
            Check24.ul.callback.login,
            undefined,
            enableMfa
        );
    },
    loginChallengeQuestionListener: (e) => {
        e.preventDefault();
        switch(Check24.ul.loginChallengeData.responseMode) {
            case "zipcode":
                Check24.ul.loginChallengeData.zipcode = Check24.getValue("#cl_zipcode_lc").trim();

                if (!Check24.ul.loginChallengeData.zipcode) {
                    Check24.ul.error.set("#cl_zipcode_lc", ".c24-uli-error-zipcode", Check24.ul.error.msg.loginChallenge.noZipcode);
                } else {
                    var sqData = {
                        id: Check24.getValue("#cl_login_ph"),
                        sq: Check24.ul.loginChallengeData.zipcode,
                        mode: Check24.ul.loginChallengeData.questionMode
                    };
                    var sqCurrent = {
                        id: Check24.ul.loginChallengeData.questions.zipcode.question_id,
                        product: Check24.ul.loginChallengeData.questions.zipcode.product
                    }
                    Check24.ul.ajax.sqValidate(sqData, sqCurrent, Check24.ul.loginType, Check24.ul.callback.loginChallengeQuestionValidate);
                }
                break;
            case "birthday":
                Check24.ul.loginChallengeData.birthday = Check24.getValue("#cl_birthday_lc").trim();
                if (!Check24.ul.loginChallengeData.birthday) {
                    Check24.ul.error.set("#cl_birthday_lc", ".c24-uli-error-birthday", Check24.ul.error.msg.loginChallenge.noBirthday);
                } else {
                    var bdc = Check24.ul.loginChallengeData.birthday.split(".");
                    if (bdc[0] && bdc[1] && bdc[2]) {
                        if (!isNaN(parseFloat(bdc[0])) && isFinite(bdc[0]) &&
                            !isNaN(parseFloat(bdc[1])) && isFinite(bdc[1]) &&
                            !isNaN(parseFloat(bdc[2])) && isFinite(bdc[2])) {
                            if ((bdc[0] < 1 || bdc[0] > 31) || (bdc[1] < 1 || bdc[1] > 12) || (bdc[2] < 1800 || bdc[2] > 2300)) {
                                Check24.ul.error.set("#cl_birthday_lc", ".c24-uli-error-birthday", Check24.ul.error.msg.loginChallenge.falseBirthday);
                                return false;
                            }
                        } else {
                            Check24.ul.error.set("#cl_birthday_lc", ".c24-uli-error-birthday", Check24.ul.error.msg.loginChallenge.falseBirthday);
                            return false;
                        }
                    } else {
                        Check24.ul.error.set("#cl_birthday_lc", ".c24-uli-error-birthday", Check24.ul.error.msg.loginChallenge.falseBirthday);
                        return false;
                    }
                    if (bdc[0].length === 1) {
                        bdc[0] = "0" + bdc[0];
                    }
                    if (bdc[1].length === 1) {
                        bdc[1] = "0" + bdc[1];
                    }
                    Check24.ul.loginChallengeData.birthday = bdc[2] + "-" + bdc[1] + "-" + bdc[0];
                    var sqData = {
                        id: Check24.getValue("#cl_login_ph"),
                        sq: Check24.ul.loginChallengeData.birthday,
                        mode: Check24.ul.loginChallengeData.questionMode
                    };
                    var sqCurrent = {
                        id: Check24.ul.loginChallengeData.questions.birthday.question_id,
                        product: Check24.ul.loginChallengeData.questions.birthday.product
                    }
                    Check24.ul.ajax.sqValidate(sqData, sqCurrent, Check24.ul.loginType, Check24.ul.callback.loginChallengeQuestionValidate);
                    Check24.ul.initTanResendTimeout();
                }
                break;
            default:
                console.log("Question mode not supported");
        }
    },
    browserBackListener: function() {
        Check24.ul.browserBackOccured = true;
        if (Check24.ul.state !== "login") {
            if (Check24.ul.state === "points") {
                Check24.ul.afterBrowserBack();
            } else {
                if (Check24.ul.userLoginType === "check24_phone") {
                    Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
                    Check24.change("value", "#cl_login", Check24.ul.phone);
                    Check24.change("type", "#cl_login", "tel");
                } else {
                    Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                    Check24.change("value", "#cl_login", Check24.ul.email);
                    Check24.change("type", "#cl_login", "email");
                }
                Check24.ul.init("login", "nocookie");
            }
        } else {
            Check24.ul.afterBrowserBack();
        }
    },
    loginProcessListener: function(e) {
        e.preventDefault();
        Check24.ul.hideResentTanCodeText();
        var login = Check24.getValue("#cl_login_ph");
        var loginProvider = "check24_tan_email";
        if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
            loginProvider = "check24_tan_phone";
        }
        Check24.ul.ajax.loginProcess(login, loginProvider, Check24.ul.callback.loginProcess);
        Check24.ul.initTanResendTimeout();
    },
    loginCodeListener: function(e) {
        e.preventDefault();
        Check24.ul.error.unset();
        var login = Check24.getValue("#cl_login_ph");
        var tan = Check24.exists("#cl_login_tan") ? Check24.getValue("#cl_login_tan") : "";
        if (tan === "") {
            Check24.ul.error.set('.c24-uli-loginprocess .c24-uli-input-splitted', '.c24-uli-error-lptan', Check24.ul.error.msg.noValue.code);
            return false;
        }
        if (!Check24.validate.code(tan)) {
            Check24.ul.error.set('.c24-uli-loginprocess .c24-uli-input-splitted', '.c24-uli-error-lptan',Check24.ul.error.msg.pwReset.wrongCode);
            return false;
        }
        Check24.ul.loginProcessData.response = tan;
        Check24.ul.layout.setSpinner(e, Check24.document.querySelector("#c24-uli-lptan-btn"));
        Check24.ul.ajax.login(login, "", Check24.ul.loginType, Check24.ul.loginProcessData.response, Check24.ul.loginProcessData.id, Check24.ul.userData, undefined, undefined, Check24.ul.callback.login, undefined);
    },
    captchaListener: function(e) {
        try {
            var eventName = '';
            var data = {
                name: e.data.name ?? '',
                token: e.data.value ?? '',
                error: false
            }
            switch (data.name) {
                case 'uli_tsStart':
                    Check24.ul.turnstileLoading = true;
                    eventName = 'uli_ts_start';
                    break;
                case 'uli_tsToken':
                    Check24.ul.turnstileLoading = false;
                    Check24.ul.ajax.captchaValidate(data.token, Check24.ul.callback.captchaSuccess)
                    eventName = 'uli_ts_success';
                    break;
                case 'uli_tsInteractiveCallbackStart':
                    eventName = 'uli_ts_challenge';
                    break;
                case 'uli_tsInteractiveCallbackEnd':
                    break;
                case 'uli_tsError':
                    Check24.ul.turnstileLoading = false;
                    Check24.ul.callback.captchaError();
                    Check24.hide('.c24-uli-captcha iframe');
                    eventName = 'uli_ts_fail';
                    break;
                case 'size':
                    Check24.ul.layout.updateCaptchaIFrame(e.data.size ?? '');
                    break;
                default:
                    break;
            }

            try {
                if (window._mtm && eventName !== '') {
                    _mtm.push({
                        'event': eventName,
                        'uli_api_product': configService.getApiProduct(),
                        'deviceoutput': configService.getDeviceoutput(),
                        'uli_login_type': Check24.ul.loginType
                    });
                }
            } catch (e) {
            }
        } catch (e) {
            console.log('pm failed');
        }
    },
}