// FILE CREATED AUTOMATICALLY - DO NOT EDIT!

export const poJM = {
    pw_reset: {
        secure_password_strong_title: "Voraussetzung an Ihr sicheres Passwort",
        secure_password_strong_item_1: "Mindestens 8 Zeichen",
        secure_password_strong_item_2: "Es enthält mind. einen Klein- und Großbuchstaben.",
        secure_password_strong_item_3: "Es enthält mind. eine Zahl oder ein Sonderzeichen.",
        secure_password_strong_item_4: "Es hat keine Ähnlichkeit mit Ihrer <span class=\"c24-uli-login-type\">E-Mail-Adresse</span>",
        mobile_number: "Ihre Mobiltelefonnummer",
        email_address: "Ihre E-Mail-Adresse"
    },
    tan: {
        enter_code_sms: "Code aus SMS",
        enter_code_email: "Code aus E-Mail",
        ecode_was_sent: "Ein neuer Code wurde gesendet. Bitte überprüfen Sie Ihr E-Mail Postfach.",
        scode_was_sent: "Ein neuer Code wurde gesendet. Bitte schauen Sie in Ihre SMS Nachrichten.",
        security_code_sent_sld: "Geben Sie den Code ein, den wir an <span class=\"c24-uli-login-wrapper c24-uli-login-wrapper-type\">Ihre Mobiltelefonnummer</span> gesendet haben.",
        wait_for_resend: "Bitte warten Sie <span class=\"c24-uli-tan-resend-wait-time\"></span> Sekunde(n), bevor Sie einen neuen Sicherheitscode anfordern.",
        resend_possible: "Sie können jetzt ggf. einen neuen Code anfordern."
    },
    layout: {
        ux: {
            loginprocess: {
                title: "Möchten Sie eine E-Mail mit einmaligem Zugangscode erhalten?",
                titlesms: "Möchten Sie eine SMS mit einmaligem Zugangscode erhalten?",
                tantitle: "Geben Sie den Code ein"
            },
            tan: {
                phone: {
                    mobile_number: "Mobiltelefonnummer"
                },
                email: {
                    email_address: "E-Mail-Adresse"
                },
                verification: "Überprüfen Ihrer Identität",
                mfa_title: "Zwei-Faktor-Authentifizierung",
                mobile_number: "Mobiltelefonnummer",
                mobile_device: "Ihr Mobiltelefon",
                email_address: "E-Mail-Adresse",
                mails: "Ihre E-Mails"
            },
            gender: {
                female: "Frau",
                male: "Herr"
            },
            login: {
                nocookie: {
                    title: "Für <span class=\"c24-uli-title-name\">die Buchung</span> benötigen wir folgende Information",
                    email_address_short: "E-Mail oder Mobiltelefonnummer"
                }
            },
            login_other: {
                email: {
                    title: "Wie es aussieht, haben Sie sich mit einer E-Mail-Adresse bereits ein CHECK24 Kundenkonto angelegt.",
                    salutation: "Hallo %s",
                    welcome: "Willkommen",
                    email_address: "Ihre E-Mail-Adresse"
                },
                phone: {
                    title: "Wie es aussieht, haben Sie sich mit einer Mobiltelefonnummer bereits ein CHECK24 Kundenkonto angelegt.",
                    salutation: "Hallo %s",
                    welcome: "Willkommen",
                    mobile_number: "Ihre Mobiltelefonnummer"
                }
            },
            login_challenge: {
                verification: "Kurze Sicherheitsprüfung",
                your_phone: "Ihr Mobiltelefon",
                your_mails: "Ihre E-Mails"
            },
            submit: {
                verification: "Kurze Sicherheitsprüfung",
                your_phone: "Ihr Mobiltelefon",
                your_mails: "Ihre E-Mails"
            },
            social: {
                facebook: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen"
                },
                google: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen",
                    collect_points: "Jetzt anmelden und <strong>CHECK24 Punkte</strong> sammeln."
                }
            },
            pw: {
                salutation: "Hallo %s",
                welcome: "Willkommen",
                start: {
                    login_other_accout: "Melden Sie sich mit Ihrem CHECK24 Kundenkonto an.",
                    login_with_account: "Melden Sie sich mit Ihrem CHECK24 Kundenkonto an.",
                    account_exists: "Wie es aussieht, haben Sie bereits ein CHECK24 Kundenkonto.",
                    collect_points: "Jetzt anmelden und <strong>CHECK24 Punkte</strong> sammeln."
                },
                registercheck: {
                    text: "<p>Wie es aussieht haben Sie bereits ein CHECK24 Kundenkonto <span class=\"c24-uli-registercheck-email\"></span>.</p><p>Bitte geben Sie Ihr CHECK24 Passwort an, um sich mit Ihrem Konto zu verknüpfen.</p>"
                }
            },
            register: {
                with_email: "oder mit E-Mail-Adresse anmelden",
                with_mobile_number: "oder mit Mobiltelefonnummer anmelden",
                email: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen"
                },
                sameemail: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen"
                },
                phone: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen"
                },
                superaccount: {
                    salutation: "Hallo %s",
                    welcome: "Willkommen"
                }
            },
            pwreset: {
                email: {
                    forgot_password: "Haben Sie Ihr CHECK24 Passwort vergessen?",
                    your_email_address: "Ihre E-Mail-Adresse"
                },
                phone: {
                    forgot_password: "Haben Sie Ihr CHECK24 Passwort vergessen?"
                },
                mcode: {
                    verification: "Geben Sie den Code ein",
                    mobile_number: "Ihre Mobiltelefonnummer",
                    mobile_device: "Ihr Mobiltelefon",
                    email_address: "Ihre E-Mail-Adresse",
                    mails: "Ihre E-Mails"
                },
                ecode: {
                    verification: "Geben Sie den Code ein",
                    mails: "Ihre E-Mails",
                    mobile_device: "Ihr Mobiltelefon"
                },
                sq: {
                    verification: "Überprüfen Ihrer Identität"
                },
                zipcode: {
                    verification: "Überprüfen Ihrer Identität",
                    email_address: "Ihrer E-Mail-Adresse",
                    mobile_number: "Ihrer Mobiltelefonnummer"
                },
                birthday: {
                    verification: "Überprüfen Ihrer Identität",
                    email_address: "Ihrer E-Mail-Adresse",
                    mobile_number: "Ihrer Mobiltelefonnummer"
                },
                pw: {
                    create_new_password: "Neues Passwort erstellen",
                    mobile_number: "Mobiltelefonnummer",
                    email_address: "E-Mail-Adresse"
                },
                login: {
                    title: "Willkommen zurück!"
                }
            },
            renew: {
                start: {
                    check_account: "Sind Sie sicher, dass Sie kein CHECK24 Kundenkonto besitzen?"
                },
                pw: {
                    new_password: "Willkommen",
                    mobile_number: "Mobiltelefonnummer",
                    email_address: "E-Mail-Adresse"
                }
            },
            points: {
                collect_points: "<div style=\"font-size: 23px; font-weight: normal; line-height: 28px;\">CHECK24 Punkte sammeln</div>",
                email_address_required: "Für <span class=\"c24-uli-title-name\">die Buchung</span> benötigen wir Ihre <span class=\"c24-uli-nowrap\">E-Mail-Adresse</span>"
            },
            registercheck: {
                title: "E-Mail-Adresse hinzufügen"
            },
            captcha: {
                title: "Kurze Prüfung Ihrer Identität"
            }
        },
        post_functions: {
            login_layer: {
                nocookie: {
                    sign_in: "Anmelden",
                    sign_in_register: "CHECK24 Kundenkonto anlegen"
                }
            }
        }
    },
    error: {
        msg: {
            noValue: {
                email_or_phone: "Bitte geben Sie Ihre E-Mail-Adresse oder Mobiltelefonnummer an"
            },
            login: {
                fail_code: "Ungültiger Code. Überprüfen Sie den Code und versuchen Sie es dann erneut.",
                last: "Achtung: letzter Anmeldeversuch",
                blocked: "Ihr Kundenkonto ist aufgrund mehrfach fehlerhafter Versuche für eine Stunde gesperrt. Bitte wenden Sie sich an den <a href=\"https://www.check24.de/unternehmen/kontakt/\" target=\"_blank\">Kundenservice</a>.",
                fraud: "Aus Sicherheitsgründen wurde Ihr CHECK24 Kundenkonto vorübergehend blockiert. Bitte erneuern Sie Ihr Passwort über <a class=\"c24-uli-cl-pwreset-trigger-text\">Passwort vergessen</a>, um wieder Zugriff zu erhalten.",
                fail: "Ihr Passwort ist nicht korrekt",
                fail_phone: "Ihr Passwort ist nicht korrekt"
            },
            validate: {
                email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
                blacklisted_email: "Mit dieser E-Mail-Adresse kann kein Konto erstellt werden. Bitte vermeiden Sie Sonderzeichen wie beispielsweise ä, ö, ü.",
                email_or_phone: "Bitte geben Sie eine gültige E-Mail-Adresse oder Mobiltelefonnummer ein",
                mobile: "Bitte geben Sie eine gültige Mobiltelefonnummer ein",
                mobile_used: "Es gibt bereits ein CHECK24 Kundenkonto mit der angegebenen Mobiltelefonnummer. Bitte benutzen Sie eine andere Mobiltelefonnummer."
            },
            no_value: {
                email: "Bitte geben Sie Ihre E-Mail-Adresse an",
                phone: "Bitte geben Sie Ihre Mobiltelefonnummer an",
                pw: "Bitte geben Sie Ihr Passwort ein",
                code: "Bitte geben Sie den Code ein",
                sq: "Bitte geben Sie die Antwort ein.",
                firstname: "Bitte geben Sie Ihren Vornamen ein",
                lastname: "Bitte geben Sie Ihren Nachnamen ein"
            },
            pw_reset: {
                no_code: "Bitte geben Sie einen Code ein",
                wrong_code: "Ungültiger Code. Überprüfen Sie den Code und versuchen Sie es dann erneut.",
                no_zip: "Bitte geben Sie Ihre Postleitzahl ein",
                false_zip: "Die eingegebene Postleitzahl ist ungültig",
                wrong_zip: "Die Postleitzahlen stimmen nicht überein",
                no_pw: "Bitte wählen Sie ein Passwort",
                short_pw: "Das Passwort sollte zwischen 8 und 50 Zeichen lang sein",
                no_birthday: "Bitte geben Sie Ihr Geburtsdatum an",
                false_birthday: "Bitte geben Sie ein gültiges Geburtsdatum an",
                wrong_birthday: "Die Geburtsdaten stimmen nicht überein",
                wrong_answer: "Die eingegebene Antwort ist nicht korrekt."
            },
            register: {
                short_pw: "Das Passwort sollte zwischen 8 und 50 Zeichen lang sein"
            },
            pw: {
                length: "<strong>Achtung!</strong> Die Grenze von 50 Zeichen wurde erreicht!",
                repeat: "Die Passwörter stimmen nicht überein",
                repeat_empty: "Bitte wiederholen Sie Ihre Eingabe",
                short_pw: "Das Passwort sollte zwischen 8 und 50 Zeichen lang sein",
                other: "Bitte wählen Sie ein anderes Passwort",
                strong: "Das von Ihnen gewählte Passwort entspricht nicht den Voraussetzungen."
            },
            sq: {
                last_chance: "Achtung: Letzter Eingabeversuch.",
                blocked: "Aufgrund mehrfach fehlerhafter Eingaben ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Kundenservice unter der <a class=\"c24-uli-sq-contact\" href=\"tel:08924241230\">089 - 24 24 12 30</a>."
            },
            deactivate: {
                blocked: "Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den <a href=\"https://www.check24.de/unternehmen/kontakt/\" target=\"_blank\">Kundenservice</a>.",
                validate: "Es ist ein Fehler bei der Verifizierung aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den <a href=\"https://www.check24.de/unternehmen/kontakt/\" target=\"_blank\">Kundenservice</a>."
            },
            login_challenge: {
                no_zip: "Bitte geben Sie Ihre Postleitzahl ein",
                false_zip: "Die eingegebene Postleitzahl ist ungültig",
                wrong_zip: "Die Postleitzahlen stimmen nicht überein",
                no_birthday: "Bitte geben Sie Ihr Geburtsdatum an",
                false_birthday: "Bitte geben Sie ein gültiges Geburtsdatum an",
                wrong_birthday: "Die Geburtsdaten stimmen nicht überein"
            }
        }
    },
    registerList: {
        item_1: "Alle Abschlüsse im Überblick",
        item_2: "Bei den CHECK24 Vergleichen Zeit sparen",
        item_3: "Exklusive Angebote und Gutscheine"
    },
    user_prefill: {
        email_address: "E-Mail-Adresse",
        mobile_number: "Mobiltelefonnummer"
    },
    trigger: {
        register: {
            sameemail: {
                email_address: "E-Mail-Adresse"
            },
            samephone: {
                phone_number: "Mobiltelefonnummer"
            }
        },
        pwreset: {
            mobile_number: "Ihre Mobiltelefonnummer",
            email_address: "Ihre E-Mail-Adresse"
        }
    },
    browser_back_listener: {
        start: {
            placeholder: {
                mobile_number: "Mobiltelefonnummer",
                email_address: "E-Mail-Adresse"
            }
        }
    },
    login: {
        proceed_button: "weiter",
        account_exists: "Wie es aussieht, haben Sie bereits ein CHECK24 Kundenkonto."
    },
    social: {
        mobile_number: "Mobiltelefonnummer",
        email_address: "E-Mail-Adresse",
        sign_in_with_account: "Melden Sie sich mit Ihrem CHECK24 Kundenkonto an."
    },
    register: {
        mobile_number: "Mobiltelefonnummer",
        email_address: "E-Mail-Adresse"
    },
    callback: {
        exists: {
            mobile_number_label: "Ihre Mobiltelefonnummer",
            mobile_number_placeholder: "Mobiltelefonnummer",
            email_address_label: "Ihre E-Mail-Adresse",
            email_address_placeholder: "E-Mail-Adresse",
            error_general: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        login: {
            mobile_number: "Ihre Mobiltelefonnummer",
            email_address: "Ihre E-Mail-Adresse",
            pwreset: {
                mobile_number: "Ihre Mobiltelefonnummer",
                email_address: "Ihre E-Mail-Adresse"
            },
            unexpected_error: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        login_challenge: {
            sms: "SMS",
            email: "E-Mail",
            recipient: "<span class=\"c24-uli-lc-text-type\">E-Mail</span> mit einem Code erhalten<br /><span class=\"c24-uli-lc-user-data\">****</span>",
            unexpected_error: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        login_challenge_start: {
            error: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        convert_mobile: {
            mobile_number: "Ihre Mobiltelefonnummer"
        }
    },
    set_text: {
        login_faq: "Bitte geben Sie Ihre E-Mail-Adresse oder Mobiltelefonnummer an. Diese benötigen Sie zur Anmeldung in Ihrem persönlichen CHECK24 Kundenkonto."
    },
    sld: {
        headline: "Konto schützen",
        with_number: "Überprüfen Sie Ihre hinterlegte Mobiltelefonnummer, damit wir Ihr Kundenkonto noch besser schützen können."
    }
};