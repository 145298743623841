import {sendRequest} from "./sendRequest";
import {configService} from "./services/configService";
import Check24 from "./unifiedlogin_mobile";

export default {
    exists: function (email, type, userData, callback, callbackError) {
        sendRequest(
            "ajax/user/existsextended/",
            {
                a: encodeURIComponent(email),
                b: encodeURIComponent(type),
                c: encodeURIComponent(userData.exists),
                e: configService.getPoints(),
                f: configService.getTransactionType(),
                g: configService.getCustomParam(),
            },
            callback,
            callbackError
        );
    },
    login: function(email, password, loginType, tan, loginProcessId, userData, challengeId, challengeResponse, callback, callbackError, enableMfa) {
        sendRequest(
            "ajax/user/login/",
            {
                login: encodeURIComponent(email),
                password:  encodeURIComponent(password),
                type: loginType,
                tan: tan,
                login_process_id: loginProcessId,
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                terms: !userData.terms ? "yes" : undefined,
                custom_param: configService.getCustomParam(),
                challenge_id: challengeId,
                challenge_response: challengeResponse,
                auth_phone: configService.getLoginDataSet(),
                enable_mfa: enableMfa
            },
            callback,
            callbackError
        );
    },
    loginChallengeStart: function(challengeId, challengeType, login, type, callback, callbackError) {
        var requestAllowed = Check24.setTanSessionData("lc");
        if  (!requestAllowed) {
            callback(JSON.stringify({
                "status": 200
            }));
            return;
        }
        sendRequest(
            "ajax/user/loginchallenge/",
            {
                challenge_id: challengeId,
                challenge_type:  challengeType,
                login: encodeURIComponent(login),
                type: encodeURIComponent(type)
            },
            callback,
            callbackError
        );
    },
    convertMobile: function(phone, password, callback, callbackError) {
        sendRequest(
            "ajax/user/phone/",
            {
                login: encodeURIComponent(phone),
            },
            callback,
            callbackError
        );
    },
    register: function(email, password, firstname, lastname, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/register/",
            {
                login: encodeURIComponent(email),
                password: encodeURIComponent(password),
                firstname: encodeURIComponent(firstname),
                lastname: encodeURIComponent(lastname),
                email: encodeURIComponent(email),
                type: loginType,
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    pwResetTan: function(type, pwResetData, loginType, callback, callbackError) {
        if (pwResetData.method !== null && (Check24.ul.pwResetMode === "email" || Check24.ul.pwResetMode === "phone")) {
            var requestAllowed = Check24.setTanSessionData("pw_reset");
            if (!requestAllowed) {
                callback(JSON.stringify({
                    "data": {
                        "status_code": 668
                    }
                }));
                return;
            }
        }
        sendRequest(
            "ajax/user/passwordresetauth/",
            {
                login: encodeURIComponent(pwResetData.id),
                tan_method: encodeURIComponent(pwResetData.method) === "null" ? "" : encodeURIComponent(pwResetData.method),
                tan_code: encodeURIComponent(pwResetData.mcode) === "null" ? "" : encodeURIComponent(pwResetData.mcode),
                secure_tan_code: encodeURIComponent(pwResetData.ecode) === "null" ? "" : encodeURIComponent(pwResetData.ecode),
                zipcode: encodeURIComponent(pwResetData.zipcode) === "null" ? "" : encodeURIComponent(pwResetData.zipcode),
                birthday: encodeURIComponent(pwResetData.birthday) === "null" ? "" : encodeURIComponent(pwResetData.birthday),
                type: loginType
            },
            callback,
            callbackError
        );
    },
    pwResetPw: function (pwResetData, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/passwordreset/",
            {
                login: encodeURIComponent(pwResetData.id),
                password: encodeURIComponent(pwResetData.password) === "null" ? "" : encodeURIComponent(pwResetData.password),
                tan_code: encodeURIComponent(pwResetData.mcode) === "null" ? "" : encodeURIComponent(pwResetData.mcode),
                secure_tan_code: encodeURIComponent(pwResetData.ecode) === "null" ? "" : encodeURIComponent(pwResetData.ecode),
                zipcode: encodeURIComponent(pwResetData.zipcode) === "null" ? "" : encodeURIComponent(pwResetData.zipcode),
                birthday: encodeURIComponent(pwResetData.birthday) === "null" ? "" : encodeURIComponent(pwResetData.birthday),
                login_instead: encodeURIComponent(pwResetData.loginInstead) === "null" ? "no" : encodeURIComponent(pwResetData.loginInstead),
                points_user: encodeURIComponent(Check24.ul.userData.points),
                type: loginType,
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    sqValidate: function(sqData, sqCurrent, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/sqvalidate/",
            {
                response: encodeURIComponent(sqData.sq),
                qproduct: encodeURIComponent(sqCurrent.product),
                qid: encodeURIComponent(sqCurrent.id),
                type: loginType,
                login: encodeURIComponent(sqData.id),
                mode: sqData.mode ? encodeURIComponent(sqData.mode) : ""
            },
            callback,
            callbackError
        );
    },
    tanNew: function(tanData, callback, callbackError) {
        var requestAllowed = Check24.setTanSessionData(tanData.context === "null" ? "" : tanData.context);
        if  (!requestAllowed) {
            callback(JSON.stringify({
                "status": 200
            }));
            return;
        }
        sendRequest(
            "ajax/user/tannew/",
            {
                login: encodeURIComponent(tanData.id),
                tan_context: encodeURIComponent(tanData.context) === "null" ? "" : encodeURIComponent(tanData.context),
                method: encodeURIComponent(tanData.method) === "null" ? "" : encodeURIComponent(tanData.method),
                type: encodeURIComponent(tanData.type),
                methodOverwrite: encodeURIComponent(tanData.methodOverwrite)
            },
            callback,
            callbackError
        );
    },
    tanValidate: function(tanData, callback, callbackError) {
        sendRequest(
            "ajax/user/tanvalidate/",
            {
                login: encodeURIComponent(tanData.id),
                tan_context: encodeURIComponent(tanData.context) === "null" ? "" : encodeURIComponent(tanData.context),
                tan: encodeURIComponent(tanData.code) === "null" ? "" : encodeURIComponent(tanData.code),
                type: encodeURIComponent(tanData.type),
                methodOverwrite: encodeURIComponent(tanData.methodOverwrite)
            },
            callback,
            callbackError
        );
    },
    userNew: function(tanData, tanContext, email, callback, callbackError) {
        sendRequest(
            "ajax/user/usernew/",
            {
                login: encodeURIComponent(tanData.id),
                password: encodeURIComponent(tanData.pw),
                firstname: tanData.firstname === null ? "" : encodeURIComponent(tanData.firstname),
                lastname: tanData.lastname === null ? "" : encodeURIComponent(tanData.lastname),
                email: encodeURIComponent(email),
                tan: tanContext === "new" ? encodeURIComponent(tanData.code) : encodeURIComponent(tanData.consecutiveTan),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    deactivate: function(tanData, callback, callbackError) {
        sendRequest(
            "ajax/user/deactivate/",
            {
                login: encodeURIComponent(tanData.id),
                tan_code: encodeURIComponent(tanData.code),
            },
            callback,
            callbackError
        );
    },
    validate: function(callback, callbackError) {
        sendRequest(
            "ajax/user/validate/",
            {
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    pointsRegister: function(startPointsRegister, callback, callbackError, timeout, callbackTimeout) {
        sendRequest(
            "ajax/user/pointsdoi/",
            {
                points_register: startPointsRegister
            },
            callback,
            callbackError,
            timeout,
            callbackTimeout
        );
    },
    config: function(callback, callbackError) {
        let data = {};
        sendRequest(
            "config.json",
            data,
            callback,
            callbackError
        );
    },
    csrfToken: function(callback, callbackError) {
        sendRequest(
            "ajax/csrftoken/",
            {},
            callback,
            callbackError
        );
    },
    editLogin: function(login, email, tan, callback, callbackError) {
        sendRequest(
            "ajax/user/editlogin/",
            {
                login: encodeURIComponent(login),
                email: encodeURIComponent(email),
                tan_code: encodeURIComponent(tan),
            },
            callback,
            callbackError
        );
    },
    loginProcess: function(login, loginProvider, callback, callbackError) {
        sendRequest(
            "ajax/user/loginprocess/",
            {
                login: encodeURIComponent(login),
                login_provider: encodeURIComponent(loginProvider),
            },
            callback,
            callbackError
        );
    },
    captchaValidate: function (token, callback, callbackError) {
        sendRequest(
            "ajax/captcha/validate/",
            {
                token: token
            },
            callback,
            callbackError
        );
    }
};
