import {configService} from "./unifiedlogin/services/configService";
import clean from "./unifiedlogin/helpers/clean";
import {getBasePath} from "./getBasePath";
import {sanitizer} from "./unifiedlogin/helpers/sanitizer";
import {configData} from "./unifiedlogin/configData";
import {browserBackEnum} from "./unifiedlogin/enum/browserBackEnum";

function getAttribute(el, attrName) {
    if (el.getAttribute(attrName) === null || el.getAttribute(attrName) === "") {
        return undefined;
    }
    return el.getAttribute(attrName);
}

export function initAttributeParams(Check24, el, fallbackDeviceoutput) {
    const config = {
        apiProduct: sanitizer.product(getAttribute(el, "api_product")),
        pwResetType:  sanitizer.allowedWords(getAttribute(el, "password_reset_type"), ["email", "tan"]),
        pwResetRef: sanitizer.url(getAttribute(el, "password_reset_ref")),
        socialLoginCallbackUrl: sanitizer.url(getAttribute(el, "social_login_callback_url")),
        socialLoginClosingUrl: sanitizer.url(getAttribute(el, "social_login_closing_url")),
        socialLoginBaseUrl: sanitizer.url(getAttribute(el, "social_login_base_url")),
        points: sanitizer.allowedWords(getAttribute(el, "points"), ["none", "checkbox", "radio"]),
        browserBack: sanitizer.allowedWords(el.getAttribute("browserback"), [browserBackEnum.ALWAYS, browserBackEnum.NONE]),
        basePathTest: sanitizer.url(getAttribute(el, "base_path")),
        transactionType: sanitizer.allowedWords(getAttribute(el, "transaction_type"), ["none", "offline", "online", "offer", "enquiry", "closure"]),
        showAnonymous: sanitizer.allowedWords(getAttribute(el, "anonymous"), ["true", "false", "register"]),
        helpWidth: sanitizer.pixel(getAttribute(el, "help_width")),
        mainWidth: sanitizer.pixel(getAttribute(el, "main_width")),
        responsive: sanitizer.allowedWords(getAttribute(el, "responsive"), ["true", "false"]),
        border: sanitizer.allowedWords(getAttribute(el, "border"), ["none"]),
        background: sanitizer.allowedWords(getAttribute(el, "background"), ["grey"]),
        customParam: getAttribute(el, "custom_param"),
        basePath: getBasePath(),
        env: "shadowDom",
        deviceoutput: sanitizer.allowedWords(getAttribute(el, "deviceoutput") || fallbackDeviceoutput, ["desktop", "tablet", "mobile", "app", "tabletapp"]),
        context: sanitizer.allowedWords(getAttribute(el, "context"), ["kb", "loginlayer", "as"]),
        mode: sanitizer.allowedWords(getAttribute(el, "mode"), ["register", "pwreset"]),
        registerType: sanitizer.allowedWords(getAttribute(el, "register_type"), ["secure"]),
        ssoEnv: sanitizer.allowedWords(getAttribute(el, "sso_env"), ["dev", "int", "test", "prod"]),
        ssoCore: getAttribute(el, "sso_core"),
        challenge: sanitizer.allowedWords(getAttribute(el, "challenge"), ["yes", "no"]),
        configKey: sanitizer.product(getAttribute(el, "config_key")),
        cookieDomain: sanitizer.domain(getAttribute(el, "cookie_domain")),
        csrfValidation: sanitizer.allowedWords(getAttribute(el, "csrf_validation"), ["true", "false"]),
        pwMode: sanitizer.allowedWords(getAttribute(el, "pw_mode"), ["default", "strong"]),
        uliReset: getAttribute(el, "uli_reset"),
        setLoginData: sanitizer.allowedWords(getAttribute(el, "set_login_data"), ["true", "false"]),
        transferToken: sanitizer.html(getAttribute(el, "transfer_token")),
        loginMode: sanitizer.allowedWords(getAttribute(el, "login_mode"), ["default", "code"]),
        forceLogin: sanitizer.allowedWords(getAttribute(el, "force_login"), ["true", "false"]),
        registerUsername: sanitizer.allowedWords(getAttribute(el, "register_username"), ["true", "false"]),
        socialRegistration: sanitizer.allowedWords(getAttribute(el, "social_registration"), ["true", "false"])
    };

    clean(config);
    configService.init(config);

    if (configService.getBackground() === 'grey') {
        Check24.addClass('.c24-uli-body', 'c24-uli-grey');
    }
    if (configService.getContext() === "kb" && !Check24.hasClass('.c24-uli-wrapper', 'c24-uli-mobile')) {
        Check24.document.querySelector(".c24-uli-wrapper").style.width = "500px";
        Check24.document.querySelector(".c24-uli").style.width = "500px";
        Check24.document.querySelector(".c24-uli-loading-screen").style.width = "500px";
    }

    // Mutable state
    Check24.ul.userLoginEmail = sanitizer.email(getAttribute(el, "login_email")) || Check24.ul.userLoginEmail;
    Check24.ul.userLoginPhone = sanitizer.phone(getAttribute(el, "login_phone")) || Check24.ul.userLoginPhone;
    Check24.ul.userLoginType = sanitizer.allowedWords(getAttribute(el, "login_type"), ["check24", "check24_phone", "facebook", "google"]) || Check24.ul.userLoginType;
    Check24.ul.uliReseted = false;

    // Load CSRF Token
    Check24.ul.ajax.csrfToken(function() {
        // Load product config
        Check24.ul.ajax.config(function (json) {
            configData.init(json);

            // Check if Turnstile should be used
            if (configData.data.hasOwnProperty("useCa") && configData.data.useCa === true) {
                Check24.ul.useCaptcha = true;
            }
            if (configData.data.hasOwnProperty("tsMode")) {
                Check24.ul.turnstileMode = configData.data.tsMode;
            }

            // Initial validation
            Check24.ul.ajax.validate(Check24.ul.callback.validate, Check24.ul.globalError);
        }, Check24.ul.globalError);
    }, Check24.ul.globalError);
}
